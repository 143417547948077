<template>
  <div
      class="layout-logo"
      v-if=" !$store.state.themeConfig.themeConfig.isCollapse"
      @click="onThemeConfigChange"
  >
    <img v-if="maxLogo" class="layout-logo-medium-img" :src="maxLogo" alt=""/>
  </div>
  <div class="layout-logo-size" v-else @click="onThemeConfigChange">
    <img v-if="minLogo" class="layout-logo-size-img" :src="minLogo" alt=""/>
  </div>
</template>
<script>
export default {
  name: 'layoutLogo',
  data() {
    return {
      minLogo: require('@/assets/images/icon.png'),
      maxLogo: require('@/assets/images/icon.png')
    };
  },
  methods: {
    onThemeConfigChange() {
      this.$store.state.themeConfig.themeConfig.isCollapse = !this.$store.state.themeConfig.themeConfig.isCollapse;
    },
  },
};
</script>

<style scoped lang="scss">
.layout-logo {
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  //   box-shadow: 0px 1px 4px rgba(0, 21, 41, 2%);
  color: var(--prev-color-primary);
  font-size: 16px;
  cursor: pointer;
  animation: logoAnimation 0.3s ease-in-out;
  &:hover {
    span {
      opacity: 0.9;
    }
  }
  &-medium-img {
    height: 50px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
}
.layout-logo-size {
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  margin: auto;

  &-img {
    width: 50px;
    height: 50px;
    margin: auto;
    animation: logoAnimation 0.3s ease-in-out;
  }
}
</style>
