import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import '@/theme/index.scss';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import './styles/index.less';
import 'vxe-table/lib/style.css';
import timeOptions from '@/libs/timeOptions';
import Pagination from '@/components/Pagination';
import pagesHeader from "@/components/pagesHeader";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.config.productionTip = false
import settings from '@/setting'
Vue.prototype.$routeProStr = settings.routePre;
Vue.prototype.$timeOptions = timeOptions;
Vue.component('Pagination', Pagination);
Vue.component("pagesHeader", pagesHeader);
Vue.prototype.bus = new Vue();
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
});
Vue.use(ElementUI);
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
