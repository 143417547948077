<template>
  <el-menu
      router
      :class="setColumnsAsideStyle"
      background-color="transparent"
      :default-active="activePath || defaultActive"
      :collapse="setIsCollapse"
      :unique-opened="getThemeConfig.isUniqueOpened"
      :collapse-transition="true"
  >
    <template v-for="val in menuList">
      <el-submenu :index="val.path" v-if="val.children && val.children.length > 0" :key="val.path">
        <template slot="title">
          <i class="ivu-icon" :class="val.icon ? 'el-icon-' + val.icon : ''"></i>
          <span>{{ val.title }}</span>
        </template>
        <el-menu-item v-for="child in val.children" :key="child.path" :index="child.path">
          <i class="ivu-icon" :class="child.icon ? 'el-icon-' + child.icon : ''"></i>
          <span>{{ child.title }}</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :index="val.path" :key="val.path">
        <i class="ivu-icon" :class="val.icon ? 'el-icon-' + val.icon : ''"></i>
        <span>{{ val.title }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'navMenuVertical',
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      defaultActive: this.$route.path,
    };
  },
  computed: {
    ...mapState('menu', ['activePath']),
    setColumnsAsideStyle() {
      return this.$store.state.themeConfig.themeConfig.columnsAsideStyle;
    },
    getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
    setIsCollapse() {
      return document.body.clientWidth < 1000 ? false : this.$store.state.themeConfig.themeConfig.isCollapse;
    },
  },
  watch: {
    $route(to) {
      this.defaultActive = to.path;
      const clientWidth = document.body.clientWidth;
      if (clientWidth < 1000) this.$store.state.themeConfig.themeConfig.isCollapse = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .center {
  text-align: center;
  margin-right: 0 !important;
  margin-left: 5px;
}
</style>
